import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Configs from '../../Configs';
import { fetchApi } from '../../AjaxUtil';
import '../../styles/report.css';
import { Skeleton } from 'antd'

const GateReportDetail = function ({ history }) {
  const historyy = useHistory();
  const { t } = useTranslation();
  const [gateName, setGateName] = useState('');
  const [duration] = useState('All'); // always = all
  const [scanInCount, setScanInCount] = useState(0);
  const [isScanInCountPending, setIsScanInCountPending] = useState(true);
  const [scanOutCount, setScanOutCount] = useState(0);
  const [isScanOutCountPending, setIsScanOutCountPending] = useState(true);

  useEffect(() => {
    if (history.location.state !== undefined) {
      setGateName(history.location.state.gateName);

      fetchGateStatisicApi(history.location.state.gateId);
    }
  }, []);

  const onSuccessCallback = (response) => {
    setScanInCount(response[0].patronEntry);
    setIsScanInCountPending(false);
    setScanOutCount(response[0].patronExit);
    setIsScanOutCountPending(false);
  };

  const onErrorCallback = () => {
    // const onErrorCallback = (response, status) => {
    // handle error
    // console.log('err', response, status);
  };

  async function fetchGateStatisicApi(gate) {
    const param = {
      gateIds: gate,
      duration,
    };

    await fetchApi(
      Configs.GATE_REPORT_DETAILS.API_METHOD,
      Configs.GATE_REPORT_DETAILS.API_PATH,
      onSuccessCallback,
      param,
      onErrorCallback,
    );
  }

  const backHandler = () => {
    historyy.goBack();
  };

  return (
    <div className='report-form'>
      <div className='report-container'>
        <div className='report-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('report_backText')}
          </div>
          <div className='report-details'>
            {t('gateReport_headerText')}
          </div>
        </div>
        <p className='report-gate-name'>{gateName}</p>
        <div className='report-details-container'>
          <div className='report-details-title'>{t('report_scanInTitle')}</div>
          <div className='report-details-text'>
            {isScanInCountPending ? <Skeleton.Input active={true} size={"small"} /> : scanInCount.toLocaleString('en-US')}
          </div>
          <div className='report-details-title'>{t('report_scanOutTitle')}</div>
          <div className='report-details-text'>
            {isScanOutCountPending ? <Skeleton.Input active={true} size={"small"} /> : scanOutCount.toLocaleString('en-US')}
          </div>
        </div>
      </div>
    </div>
  );
};

// props.history.location.state.from
GateReportDetail.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        gateId: PropTypes.string,
        gateName: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default GateReportDetail;
