import React from 'react';
import PropTypes from 'prop-types';

const Button = function (props) {
  return (
    <button
      type='submit'
      className={props.cName}
      onClick={props.funct}
      disabled={props.disable}
    >
      {props.text}
    </button>
  );
};
export default Button;
Button.propTypes = {
  cName: PropTypes.string.isRequired,
  funct: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
